import styled from "@emotion/styled";
import SideBar from "../common/SideBar";
import Banner from "./banner";
import VideoList from "./video";
import Parter from "./partner";
import {
  InnerContainer,
  InnerContainer2,
  Section1,
  Section2,
  Section3,
  TextContainer,
} from "./style";
import SliderComponent from "./slider";
import SliderComponent2 from "./slider2";
import ReportComponent from "./report";
import GoalComponent from "./goal";

export default function Main() {
  const assets = {
    images: [
      "/images/intro/image1.jpg", // 이미지 경로를 실제 이미지 경로로 변경 필요
      "/images/intro/image2.jpg",
      "/images/intro/image3.jpg",
      "/images/intro/image4.jpg",
      "/images/intro/image5.jpg",
      "/images/intro/image6.jpg",
    ],
    menus: [
      "무비보기", // 이미지 경로를 실제 이미지 경로로 변경 필요
      "쉐도잉",
      "더빙",
      "단어학습",
      "문장학습",
      "대화학습",
    ],
    texts: [
      "재미있는 애니메이션으로 수업을 시작해요",
      "문장을 따라 말해보세요",
      "무비속 주인공이 되어 리얼하게 더빙해보세요",
      "그림과 함께 단어를 쉽께 배워요",
      "배운단어로 문장 만들어 심화학습",
      "묻고 답하기를 통해 원어민 앞에서도 자신있게 말하기",
    ],
  };

  const assets2 = {
    images: ["/images/intro/tv1.png", "/images/intro/tv2.png", "/images/intro/tv3.jpg"],
  };

  return (
    <>
      <Container>
        <Banner />
        <VideoList />
        <Section1>
          <InnerContainer>
            <GoalComponent />
          </InnerContainer>
        </Section1>
        <Section2>
          <InnerContainer>
            <TextContainer>
              <h1>200개 이상의 학습용 영상 콘텐츠</h1>
            </TextContainer>
            <img src="/images/intro/level.png" alt="컨텐츠" />
          </InnerContainer>
        </Section2>
        <Section1>
          <InnerContainer>
            <TextContainer>
              <h1>자기주도형 프로그램</h1>
            </TextContainer>
            <SliderComponent assets={assets} />
          </InnerContainer>
        </Section1>
        <Section2>
          <InnerContainer2>
            <ReportComponent />
          </InnerContainer2>
        </Section2>
        <Section2>
          <InnerContainer>
            <TextContainer>
              <h1>방송에 출연한 학생이 선택한 프로그램</h1>
            </TextContainer>
            <SliderComponent2 assets={assets2} />
          </InnerContainer>
        </Section2>
        <Parter />
      </Container>
      <SideBar />
    </>
  );
}

const Container = styled.div`
  height: 100%;
  & {
    background: #fff;
  }
`;
