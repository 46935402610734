import styled from "@emotion/styled";
import { CDN } from "./functions";

export default function Parter() {
  return (
    <Component className="sixth">
      <h1>패밀리사이트</h1>
      <StandardImageList />
    </Component>
  );
}

function StandardImageList() {
  const handleClick = (url: string) => {
    window.open(url);
  };
  return (
    <Container>
      {itemData.map((item) => (
        <ImageComponent key={item.img}>
          <Image
            src={item.img}
            alt={item.title}
            loading="lazy"
            onClick={() => {
              handleClick(item.url);
            }}
          />
        </ImageComponent>
      ))}
    </Container>
  );
}

const itemData = [
  {
    img: CDN + "home/partner1.png",
    title: "샤오팡",
    url: "https://www.shaopang.net/",
  },
  // {
  //   img: CDN + "home/partner4.png",
  //   title: "반디영어교실",
  //   url: "https://a.vandi.biz/",
  // },
];

const Component = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 50px;
    &.sixth {
      width: 70%;
      margin: 0 auto;
      & > h1 {
        text-align: center;
      }
    }
  `,
  Container = styled.div`
    width: 100%;
    display: flex;
    margin-top: 30px;
    & > div {
      width: 25%;
      cursor: pointer;
      @media (max-width: 1024px) {
        width: 50%;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  `,
  ImageComponent = styled.div`
    align-self: center;
  `,
  Image = styled.img``;
